<template>
  <student-form
    :title="`DATA ${formTitle}`"
    :is-visible="isVisible"
    @update-triggered="triggerUpdate()"
  >
    <!-- form -->
    <form class="mb-6" action="#" @submit.prevent="triggerUpdate()">
      <!-- full name -->
      <label class="form-label" for="parent_name">Nama</label>
      <input type="text" id="parent_name" v-model="form.name" />

      <!-- birth year -->
      <label class="form-label" for="parent_birth_year">Tahun lahir</label>
      <input type="text" id="parent_birth_year" v-model="form.birth_year" />

      <!-- last education -->
      <span class="form-label">Pendidikan terakhir</span>
      <div
        class="mb-1"
        v-for="(edu, key) in choices.education"
        :key="`edu-${key}`"
      >
        <input
          :id="'education-' + key"
          type="radio"
          name="education"
          :value="edu"
          v-model="form.last_education"
        />
        <label
          class="ml-2 uppercase tracking-wider"
          :for="'education-' + key"
          >{{ edu }}</label
        >
      </div>

      <!-- job -->
      <span class="form-label">Pekerjaan</span>
      <div class="mb-1" v-for="(j, key) in choices.job" :key="`job-${key}`">
        <input
          :id="'job-' + key"
          type="radio"
          name="job"
          :value="j"
          v-model="form.job"
        />
        <label class="ml-2 uppercase tracking-wider" :for="'job-' + key">{{
          j
        }}</label>
      </div>
      <!-- <input type="text" id="job-custom" placeholder="lainnya..." /> -->

      <!-- keterangan pekerjaan -->
      <label class="form-label" for="parent_job_desc"
        >Keterangan pekerjaan</label
      >
      <input type="text" id="parent_job_desc" v-model="form.job_description" />

      <!-- last education -->
      <span class="form-label">Penghasilan</span>
      <div
        class="mb-1"
        v-for="(sal, key) in choices.salary"
        :key="`salary-${key}`"
      >
        <input
          :id="'salary-' + key"
          type="radio"
          name="salary"
          :value="sal"
          v-model="form.job_salary"
        />
        <label class="ml-2 uppercase tracking-wider" :for="'salary-' + key">{{
          sal
        }}</label>
      </div>

      <!-- phone -->
      <label class="form-label" for="parent_phone">No. telp. Genggam</label>
      <input type="text" id="parent_phone" v-model="form.phone" />
    </form>
  </student-form>
</template>

<script>
import _clone from "lodash/clone";
import _find from "lodash/find";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},
      formDefault: {},
      choices: {
        education: [
          "sd/sederajat",
          "smp/sederajat",
          "sma/sederajat",
          "d1",
          "d2",
          "d3",
          "d4",
          "s1",
          "s2",
          "s3",
        ],
        job: [
          "tidak bekerja",
          "petani",
          "pns/tni/polri",
          "karyawan swasta",
          "pedagang kecil",
          "pedagang besar",
          "wiraswasta",
          "wirausaha",
          "buruh",
          "pensiunan",
          "tki",
          "sudah meninggal",
        ],
        salary: [
          "< Rp500.000,00",
          "Rp500.000,00 - Rp999.999,00",
          "Rp1.000.000,00 - Rp1.999.999,00",
          "Rp2.000.000,00 - Rp4.999.999,00",
          "Rp5.000.000,00 - Rp20.000.000,00",
          "> Rp20.000.000,00",
        ],
      },
    };
  },
  watch: {
    selected_form(val) {
      // let isParentsExist = item.parents !== undefined || item.parents !== null;
      if (val === null) {
        return;
      }
      let parents = this.selected_student.parents;
      let filter = {};
      switch (val) {
        case "father":
          filter = { gender: 1, is_guardian: 0 };
          break;
        case "mother":
          filter = { gender: 0, is_guardian: 0 };
          break;
        case "guardian":
          filter = { is_guardian: 1 };
          break;
        default:
          this.form = _clone(this.formDefault);
          return;
      }
      this.form = _clone(_find(parents, filter));
    },
  },
  methods: {
    async triggerUpdate() {
      await this.updateParent(this.form);
    },
    ...mapActions("student", ["updateParent"]),
  },
  computed: {
    isFormTypeValid() {
      let validTypes = ["father", "mother", "guardian"];
      return validTypes.includes(this.selected_form);
    },
    formTitle() {
      let maps = { father: "Ayah", mother: "Ibu", guardian: "wali" };
      return maps[this.selected_form];
    },
    isVisible() {
      return this.form_visibility === true && this.isFormTypeValid;
    },
    ...mapGetters("student", [
      "selected_form",
      "form_visibility",
      "selected_student",
    ]),
  },
  mounted() {
    this.formDefault = _clone(this.form);
  },
  components: {
    StudentForm: () =>
      import("@/components/Student/InformationForm/StudentForm.vue"),
  },
  name: "ParentForm",
};
</script>